import { createI18n } from 'vue-i18n'
import en from './en'
import zh from './zh-CN'

const I18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('open_lang') || 'zh-CN',
  messages: {
    en, 'zh-CN': zh
  }
})

export default I18n
