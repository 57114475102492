import router from './index'
import pinia from '@/stores'
import { useAppStore } from '@/stores/app'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import queryString from 'query-string'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const app = useAppStore(pinia)

const dealUrlQuerystring = () => {
  const params = queryString.parse(location.search)
  let resultToken = ''
  let resultLang = 'zh-CN'
  console.log('location.search:', Object.assign({}, params))
  if (params.lang) {
    // 除了中文外其他语种均为英文
    resultLang = params.lang.indexOf('zh') !== -1 ? 'zh-CN' : 'en'
    Reflect.deleteProperty(params, 'lang')
  }
  if (params.token) {
    resultToken = params.token as string
    Reflect.deleteProperty(params, 'token')
  }
  return { params, token: resultToken, lang: resultLang }
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  // 处理头部标签激活状态
  to.name && app.setActiveMenu(to.path.slice(1))
  //  处理语言
  if (to.query.lang) {
    const { params, lang } = dealUrlQuerystring()
    app.setLanguage(lang)
    next({ path: to.path, query: { ...params } })
  } else { next() }
})

router.afterEach(() => {
  NProgress.done()
})
