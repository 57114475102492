
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true,
      default: 'email'
    },
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    },
    color: {
      type: String,
      default: '#FFFFFF'
    }
  },
  setup (props) {
    return {
      props
    }
  }
})
