export default {
  'zh-CN': '简体中文',
  en: 'English',
  sysName: '开放平台',
  document: '文档中心',
  accessNow: '立即接入',
  tDocument: '技术文档',
  slogan: '为开发者提供便捷的接入体验',
  slogan1: '轻松将我们的物流服务集成到您的企业系统',
  resourceAdvantages: '资源优势',
  slogan2: '强大的开放服务能力，为您的业务发展保驾护航',
  slogaRich: '丰富的对接场景',
  slogaRichContent: '国际快递、电商服务等多时效方案满足您多样的物流需求',
  slogaRich1: '接入辅助',
  slogaRichContent1: '完善的线上文档、手册及 售后QQ群支持服务',
  slogaRich2: '多方式接入',
  slogaRichContent2: '提供开发者接入或 三方软件接入',
  slogaRich3: '完善的对接支持',
  slogaRichContent3: '完善的对接服务，提供沙箱测试环境',
  capabilityList: '开放能力列表',
  capabilityListContent: '',
  accesstype: '接入类型',
  shop: '商家',
  shopContent: '通过系统对接，在您的订单管理系统线上发货',
  shopContent2: '',
  thirdErp: '第三方ERP',
  thirdErpCotent: '集成顺丰国际物流服务到您的平台，助力您的客户快速使用',
  thirdErpCotent2: '',
  logisticspartner: 'ERP用户',
  logisticspartnerCotent: '准备使用第三方ERP平台，且该平台已集成了顺丰国际的服务',
  logisticspartnerCotent2: '',

  precess1: '注册信息',
  precess2: '创建应用',
  precess3: '联调上线',
  precess4: '申请上线',
  accessnowBtn: '立即接入',
  viewAccessDocuments: '查看接入文档',
  accessProcess: '接入流程',
  ifNeedHelp: '是否需要帮助？对接常见问题',
  helpCenter: '帮助中心',
  noData: '暂无数据',
  noDocument: '你暂未选择任何文档',
  header: {
    sysName: '开放平台',
    home: '首页',
    document: '文档中心',
    question: '常见问题',
    sfInternetWeb: '顺丰国际',
    console: '控制台',
    shopHome: '商家后台'
  },
  footer: {
    sysName: '开放平台',
    document: '文档中心',
    apiDoc: 'API文档',
    accessAssist: '接入辅助',
    question: '常见问题',
    technologyHelp: '技术支持',
    friendLint: '友情链接',
    sfInternetWeb: '顺丰国际',
    sfTwebLink: '顺丰科技',
    servicewebLink: '企业服务平台',
    sfwebLink: '顺丰官网',
    sfOpenLink: '顺丰科技开放平台'
  },
  placeholder: {
    input: '请输入',
    select: '请选择'
  }
}
