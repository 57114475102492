import { createApp } from 'vue'
import pinia from '@/stores'
import App from './App.vue'
import router from './router'
import I18n from './i18n'
import './router/permission'
import svgPlugin from '@/components/SvgIcon/plugin'

import './styles/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// document.title = 'OPEN API'

createApp(App).use(router).use(I18n).use(pinia).use(svgPlugin, { imports: [] }).use(ElementPlus).mount('#app')
