<template>
    <div class="sf-content bg-opWhite">
        <router-view/>
    </div>
</template>

<script setup="{name: 'SfContent'}" lang="ts">

</script>

<style scoped>
.sf-content{
    margin-top: 60px;
    min-height: 500px;
}
</style>
