import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "svg-icon",
    style: _normalizeStyle({
        width: _ctx.props.width + 'px',
        height: _ctx.props.height + 'px',
        color: _ctx.props.color
      })
  }, [
    _createElementVNode("use", {
      "xlink:href": `#icon-${_ctx.props.name}`,
      fill: _ctx.props.color
    }, null, 8, _hoisted_1)
  ], 4))
}