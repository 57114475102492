// @ts-check
import { defineStore, acceptHMRUpdate } from 'pinia'
import I18n from '@/i18n'
import Axios from 'axios'
// import { MenuItemInterface } from '@/types'
type langType='zh-CN'|'en'
const getCSSVar = (varString:string):string => {
  const rootStyle = getComputedStyle(document.documentElement)
  return rootStyle?.getPropertyValue(varString)?.trim()
}

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    lang: localStorage.getItem('open_lang'),
    fullscreenLoading: false,
    activeMenu: '',
    customColor: {
      opPrimary: '#1F1F1F',
      opSecondary: '#E31D33'
    } // tailwindConfig定义的css变量
    // documentPage: { id: 1, subMenuList: [] } as MenuItemInterface, // 文档中心
    // questionPage: { id: 2, subMenuList: [] } as MenuItemInterface// 常见问题
  }),
  getters: {
  },
  actions: {
    changeLoading (status = false) {
      this.fullscreenLoading = status
    },
    setLanguage (lang = 'zh-CN') {
      // 只支持中英文，兜底方案英文
      if (!['zh-CN', 'en'].includes(lang)) {
        lang = 'en'
      }
      this.lang = lang
      I18n.global.locale.value = lang as langType
      localStorage.setItem('open_lang', lang)
    },
    setActiveMenu (path:string) {
      this.activeMenu = path
    },
    genCustomColor () {
      const opPrimary = getCSSVar('--color-primary')
      const opSecondary = getCSSVar('--color-secondary')
      opPrimary && (this.customColor.opPrimary = opPrimary)
      opSecondary && (this.customColor.opSecondary = opSecondary)
    }
    // async getAppData () {
    //   const { data: ResData } = await Axios.get<{data:MenuItemInterface[]}>('/openapi/console/noLogin/online/doc/menu/list')
    //   let documentId = 0; let documentMenu = {}; let questionId = 0; let questionMenu = {}
    //   ResData.data?.forEach(item => {
    //     if ((item.id === 1 || item.menuNameCn === '文档中心' || item.menuNameEn === 'Document Center') && item.pid === 0) {
    //       documentId = item.id
    //       documentMenu = item.subMenuList
    //     }
    //     if ((item.id === 2 || item.menuNameCn === '常见问题' || item.menuNameEn === 'common problem') && item.pid === 0) {
    //       questionId = item.id
    //       questionMenu = item.subMenuList
    //     }
    //   })
    //   this.documentPage = {
    //     id: documentId, subMenuList: documentMenu as MenuItemInterface[]
    //   }
    //   this.questionPage = {
    //     id: questionId, subMenuList: questionMenu as MenuItemInterface[]
    //   }
    // }
  }
})
