export default {
  'zh-CN': '简体中文',
  en: 'English',
  sysName: 'SF international APIs',
  document: 'Document center',
  accessNow: 'Start with tutorials',
  tDocument: 'View API docs',
  slogan: 'Empower your global supply chain with our APIs',
  slogan1: 'Build seamless logistic experiences into your own applications. SF international takes care of the shipping so you can focus on what\'s important: increasing efficiency and improving your customer experience',
  resourceAdvantages: 'Reliable API that meets your business needs',
  slogan2: "By leveraging SF international's endpoints, businesses can streamline, automate, and gain control of their shipping process with our suite of flexible API solutions",
  slogaRich: 'Scalable API for your business',
  slogaRichContent: 'We provide both express delivery and e-commerce shipping services that meets the business demands for all volume',
  slogaRich1: 'Easy to connect',
  slogaRichContent1: 'Check out the developer portal and start crafting a seamless experience for global shipping',
  slogaRich2: 'Platform & ERP',
  slogaRichContent2: 'We support different integration method, Whether you are a direct SF integration customer or courier service platform',
  slogaRich3: 'Integration support',
  slogaRichContent3: 'Get integration guide from the Q&A, or reach out to us via email',
  capabilityList: 'Get started with our tutorials',
  capabilityListContent: 'API introductions to guide you through optimizing your supply chain for more powerful outcomes',
  accesstype: 'One API. For many use cases',
  shop: 'Seller',
  shopContent: 'Start shipping with us from your order management system',
  shopContent2: '',
  thirdErp: 'Independent software vendors',
  thirdErpCotent: 'Integrate our service to your platform, enlightening your customers with more shipping options',
  thirdErpCotent2: '',
  logisticspartner: 'User of ERP',
  logisticspartnerCotent: 'Trying to use the third-party ERP platform, and the platform has integrated the business of SF International',
  logisticspartnerCotent2: '',

  precess1: 'Create an account at our shipping platform',
  precess2: 'Create an app',
  precess3: 'Testing integration using the online sandbox',
  precess4: 'Go live',
  accessnowBtn: 'Join now',
  viewAccessDocuments: 'View doc',
  accessProcess: 'Integration steps',
  ifNeedHelp: 'NEED HELP? Check the frequently asked questions',
  helpCenter: 'Help Center',
  noData: 'No data',
  noDocument: 'You have not selected any documents yet',
  header: {
    sysName: 'Open API',
    home: 'Home',
    document: 'API documentation ',
    question: 'Q&A',
    sfInternetWeb: 'SF international',
    team: 'SF team',
    console: 'Console',
    shopHome: 'Shipping platform'
  },
  footer: {
    sysName: 'Open API',
    document: 'Document center',
    apiDoc: 'API doc',
    accessAssist: 'API guide',
    question: 'Q&A',
    technologyHelp: 'Technical support',
    friendLint: 'Other reference',
    sfInternetWeb: 'SF international',
    sfTwebLink: 'SF technology',
    servicewebLink: 'Seller platform',
    sfwebLink: 'SF Express',
    sfOpenLink: '顺丰科技开放平台'
  },
  placeholder: {
    input: 'Please input',
    select: 'Please Select'
  }
}
